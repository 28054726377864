import { Inject } from '@angular/core';
import '@angular/compiler';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PortalSeasonModalComponent } from './portal.season.modal/portal.season.modal.component';
import { PortalSeasonFormDropdownComponent } from './portal.season.form.dropdown/portal.season.form.dropdown.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalSeasonTreeComponent } from './portal.season.tree/portal.season.tree.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(@Inject( DomSanitizer) private domSanitizer: DomSanitizer) { }
    transform(url) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@NgModule({
    declarations: [
        SafePipe,
        AppComponent,
        PortalSeasonPaginationComponent,
        PortalSeasonModalComponent,
        PortalSeasonFormDropdownComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonLoadingDefaultComponent,
        PageMainComponent,
        LayoutEmptyComponent,
        PortalSeasonTreeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }