import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.loading.season');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-season-loading-season',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.season.loading.season/view.scss */
/* src/app/portal.season.loading.season/view.scss: no such file or directory */`],
})
export class PortalSeasonLoadingSeasonComponent implements OnInit {
    @Input() className: any = "w-5 h-5 text-orange";

    public async ngOnInit() {
    }
}

export default PortalSeasonLoadingSeasonComponent;